import React, { useState } from "react";

export default function ForSaleModal(props) {
  const itemprops = props.itemProps;

  const [mainImage, setMainImage] = useState(itemprops.itemGallery[0]);
  const changeMainImage = (image) => {
    // const image = e.target.src;
    setMainImage(image);
  };
  const handleKeyDown = (e) => {
    const key = e.code;
  };
  return (
    <>
      <div className="modal-item-container">
        <div className="modal-left-side">
          <div className="modal-images-container">
            <div className="modal-image">
              <img src={mainImage.sizes.large} alt="" className="main-image" />
            </div>
            <div className="modal-gallery-images">
              {itemprops.itemGallery &&
                itemprops.itemGallery.map((image, index) => {
                  return (
                    <img
                      src={image.sizes.medium}
                      alt=""
                      className="modal-gallery-image"
                      key={"galleryImage" + index}
                      onClick={() => changeMainImage(image)}
                    />
                  );
                })}
            </div>
          </div>
        </div>
        <div className="modal-right-side">
          <div className="heading-section modal-heading-section">
            <div
              className="modal-title section-heading"
              dangerouslySetInnerHTML={{ __html: itemprops.itemTitle }}
            ></div>
            <div className="section-subheading">{itemprops.itemPrice}</div>
          </div>
          <div
            className="modal-item-text"
            dangerouslySetInnerHTML={{ __html: itemprops.itemText }}
          ></div>
          <div className="flex flex-right modal-contact-button-container">
            <div className="contact-button">
              <a
                href={`mailto:info@corbyframing.ca?subject=${itemprops.itemTitle}`}
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
