import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import NewsItem from "../components/NewsItem";

export default function News(props) {
  const [activeNewsItem, setActiveNewsItem] = useState();
  const [newsItems, setNewsItems] = useState();
  let { state } = useLocation();
  const fetchNews = () => {
    axios
      .get(
        `${process.env.REACT_APP_SQL_HOST}/wp-json/wp/v2/corby_news_item?acf_format=standard&per_page=20&page=1`
      )
      .then((res) => {
        setNewsItems(res.data);
      });
  };
  useEffect(() => {
    fetchNews();
  }, []);
  return (
    <main>
      <div className="news-container  page-container">
        <div className="heading-section">
          <div className="section-heading">news</div>
        </div>
        <div className="news-items-container">
          {newsItems &&
            newsItems.map((newsItem, index) => {
              return (
                <NewsItem
                  itemImage={newsItem.acf.main_image}
                  itemHeroImage={newsItem.acf.hero_image}
                  itemTitle={newsItem.title.rendered}
                  itemText={newsItem.excerpt.rendered}
                  itemContent={newsItem.acf.post_content}
                  isHero={false}
                  itemId={newsItem.id}
                  itemDate={new Date(newsItem.date).toLocaleDateString(
                    "en-us",
                    {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    }
                  )}
                  key={"newsItem" + index}
                  itemIndex={index}
                ></NewsItem>
              );
            })}
        </div>
      </div>
    </main>
  );
}
