import React from "react";
import radioist from "../images/about/radiost.png";
import audio1 from "../images/about/audio_20room_203.png";
import audio2 from "../images/about/audio_20room_209.png";
import audio3 from "../images/about/system-2019-2.png";
import audio4 from "../images/about/system-2019-6.png";
import audio5 from "../images/about/system-feb-20-4.png";

export default function About() {
  return (
    <main>
      <div className="about-container page-container">
        <div className="heading-section">
          <div className="section-heading">About Us</div>
        </div>
        <div className="about-info-section">
          <div className="about-info-left-side">
            <div>
              <span className="about-info-heading">Don Corby - Owner</span>
              <p>26 Carlisle rd, Freelton, ON</p>
              <p>Tel: 905.689.1976</p>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d211757.48687749892!2d-79.8569648527!3d43.35229962210833!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b7ddbec5cd2d3%3A0x7eb6a1e7f58774c8!2sCorby&#39;s%20Audio!5e0!3m2!1sen!2sca!4v1704814112927!5m2!1sen!2sca"
              // width="500"
              // height="300"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="about-info-right-side">
            <img src={radioist} alt="" />
          </div>
        </div>
        <div className="about-showroom-section">
          <div className="heading-section">
            <div className="section-heading">Our Showroom</div>
          </div>
          <div className="about-showroom-info-section">
            <img src={audio1} alt="" />
            <p>
              Our showroom and retail store is nestled in the forested wetland
              of Freelton Ontario.
            </p>
            <div>
              To compliment our experienced sales advice, we offer some upgrade
              services, including:
              <ul>
                <li>
                  Cartridge installation and calibration using AnalogMagik
                  calibration software
                </li>
                <li>Tonearm installation for most turntables</li>
                <li> Custom armboard manufacturing in both metal and wood</li>
              </ul>
            </div>
            <img src={audio2} alt="" />
            <img src={audio3} alt="" />
            <p>
              Analog gear is demonstrated with the finest supporting
              amplification and loudspeakers
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}
