import React from "react";
import { Link } from "react-router-dom";
export default function SiteSpecial(props) {
  return (
    <div className="siteSpecial hero-sidebar">
      <div className="container-heading text-500">{props.itemHeading}</div>
      <div className="item">
        <div className="item-image">
          <img src={props.itemImage} />
        </div>
        <div className="item-details">
          <div className="item-heading">
            <div className="item-title">{props.itemTitle}</div>
          </div>
          <div
            className="item-text"
            dangerouslySetInnerHTML={{ __html: props.itemText }}
          ></div>
        </div>
      </div>
      <div className="flex flex-right">
        <Link to="/Shop" className="button ">
          See all specials
        </Link>
        <div className="button contact-button">
          <a href={`mailto:info@corbyframing.ca?subject=${props.itemTitle}`}>
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
}
