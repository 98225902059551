import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import classNames from "classnames";

export default function BrandNav({ isOpen, setClosed }) {
  const [brandList, setBrandList] = useState();
  const [electronicsList, setElectronicsList] = useState();
  const [analogList, setAnalogList] = useState();
  const [loudspeakersList, setLoudspeakersList] = useState();
  const [accessoriesList, setAccessoriesList] = useState();
  const [activeCategory, setActiveCategory] = useState("electronics");

  const setCategory = (category) => {
    setActiveCategory(category);
  };

  const configureBrandLiist = (list) => {
    let electronics = list.filter((item) => {
      return item.category === "electronics";
    });
    let analog = list.filter((item) => {
      return item.category === "analog";
    });
    let loudspeakers = list.filter((item) => {
      return item.category === "loudspeakers";
    });
    let accessories = list.filter((item) => {
      return item.category === "accessories";
    });
    setElectronicsList(electronics);
    setAnalogList(analog);
    setLoudspeakersList(loudspeakers);
    setAccessoriesList(accessories);
  };

  const fetchBrandList = () => {
    axios
      .get(`${process.env.REACT_APP_SQL_HOST}/wp-json/corby/v1/brandList`)
      .then((res) => {
        configureBrandLiist(res.data);
        setBrandList(res.data);
      });
  };
  useEffect(() => {
    fetchBrandList();
  }, []);
  return (
    <div className={classNames("brand-nav", isOpen ? "brand-nav-open" : "")}>
      <>
        <div
          className={classNames(
            "brand-nav-category",
            activeCategory === "electronics" ? "brand-nav-active-category" : ""
          )}
        >
          <div
            onClick={() => setCategory("electronics")}
            className="brand-nav-category-title"
          >
            Electronics
          </div>
          <ul className="brand-nav-list">
            {electronicsList &&
              electronicsList.map((brand, index) => {
                return (
                  <li key={"brand" + brand.id}>
                    <Link
                      to={`/Brand/${brand.brand_name}`}
                      relative="path"
                      className="brand-nav-name"
                      onClick={setClosed}
                    >
                      {brand.brand_name}
                    </Link>
                  </li>
                );
              })}
          </ul>
        </div>
        <div
          className={classNames(
            "brand-nav-category",
            activeCategory === "analog" ? "brand-nav-active-category" : ""
          )}
        >
          <div
            onClick={() => setCategory("analog")}
            className="brand-nav-category-title"
          >
            Analog
          </div>
          <ul className="brand-nav-list">
            {analogList &&
              analogList.map((brand, index) => {
                return (
                  <li key={"brand" + brand.id}>
                    <Link
                      to={`/Brand/${brand.brand_name}`}
                      className="brand-nav-name"
                      onClick={setClosed}
                    >
                      {brand.brand_name}
                    </Link>
                  </li>
                );
              })}
          </ul>
        </div>
        <div
          className={classNames(
            "brand-nav-category",
            activeCategory === "loudspeakers" ? "brand-nav-active-category" : ""
          )}
        >
          <div
            onClick={() => setCategory("loudspeakers")}
            className="brand-nav-category-title"
          >
            Loudspeakers
          </div>
          <ul className="brand-nav-list">
            {loudspeakersList &&
              loudspeakersList.map((brand, index) => {
                return (
                  <li key={"brand" + brand.id}>
                    <Link
                      to={`/Brand/${brand.brand_name}`}
                      className="brand-nav-name"
                      onClick={setClosed}
                    >
                      {brand.brand_name}
                    </Link>
                  </li>
                );
              })}
          </ul>
        </div>
        <div
          className={classNames(
            "brand-nav-category",
            activeCategory === "accessories" ? "brand-nav-active-category" : ""
          )}
        >
          <div
            onClick={() => setCategory("accessories")}
            className="brand-nav-category-title"
          >
            Accessories
          </div>
          <ul className="brand-nav-list">
            {accessoriesList &&
              accessoriesList.map((brand, index) => {
                return (
                  <li key={"brand" + brand.id}>
                    <Link
                      to={`/Brand/${brand.brand_name}`}
                      className="brand-nav-name"
                      onClick={setClosed}
                    >
                      {brand.brand_name}
                    </Link>
                  </li>
                );
              })}
          </ul>
        </div>
      </>
    </div>
  );
}
