import React from "react";
import { Link } from "react-router-dom";
export default function BrandCard(props) {
  const brand = props.brand;
  return (
    <li>
      <article className="brand-card">
        <img
          src={brand.acf.brand_logo.url}
          alt={brand.acf.brand_logo.alt}
          className="brand-logo"
        />
        <img
          src={brand.acf.brand_image.url}
          alt={brand.acf.brand_image.alt}
          className="brand-image"
        />
        <div className="brand-info">
          <p dangerouslySetInnerHTML={{ __html: brand.acf.card_text }}></p>
          {/* {brand.acf.isdistributor === "true" ? ( */}
          <Link to={`/Brand/${brand.title.rendered}`} className="small-button">
            Discover...
          </Link>
        </div>
      </article>
    </li>
  );
}
