import React from "react";
import classNames from "classnames";

export default function ShopSiteSpecial({ item, active }) {
  return (
    <div
      className={classNames(
        "site-special",
        active ? "site-special-active" : ""
      )}
    >
      <div className="site-special-left">
        <img src={item.acf.feature_image} alt="" />
      </div>
      <div className="site-special-right">
        <div className="section-subheading">{item.title.rendered}</div>
        <div
          className="site-special-text"
          dangerouslySetInnerHTML={{ __html: item.content.rendered }}
        ></div>
        <div className="flex flex-right">
          <div className="contact-button">
            <a
              href={`mailto:info@corbyframing.ca?subject=${item.title.rendered}`}
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
