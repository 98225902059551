import React from "react";
import mat from "../images/mat/corbymat_206.png";

export default function TurntableMat() {
  return (
    <div>
      {/* <div className="heading-section">
        <div className="section-heading">Corby's Audio Turntable Mat</div>
      </div> */}
      <div className="turntable-mat-info">
        <div className="left-side">
          <img src={mat} alt="" />
        </div>
        <div className="right-side">
          <p>
            With our Premium Carbon Fibre turntable mat, you can elevate the
            performance of your analog playback. The carbon fiber enables a
            quieter background which in turn produces more detail. This
            translates into a more focussed soundstage, better instrument
            separation and definition. Better defined bottom end can also be
            expected. Tighter bass notes produces noticeable detail in the lower
            musical spectrum. Overall a wider, deeper, more detailed soundstage
            will enhance your listening experience.
          </p>
          <p>
            Manufactured by a company that primarily supplies aerospace
            customers, using multiple layers of aerospace grade carbon fiber
            materials and manufacturing processes. These mats are unlike others
            made with inferior processes that use only one layer of carbon
            fiber, and filler to ensure flatness. Both sides of this mat have
            been manufactured to precise tolerances inside of custom hard
            tooling made specifically for the application. The mats are then
            milled with a high quality CNC machine. The entire process ensures
            very precise dimensions, and ideal flatness of the mat, that
            practically vacuums itself to the turntable and record.
          </p>
          <div className="section-subheading">Features</div>
          <ul>
            <li>
              Stiffer than most other turntable mats, which is key to the
              audible results
            </li>
            <li>
              CNC milling ensures center hole is concentric to the outer
              diameter
            </li>{" "}
            <li>Machined relief area for the label</li>{" "}
            <li>Chamfered edge for easy grip of lp</li>{" "}
            <li>
              Incredibly precise flatness within 0.005" allowing the mat to form
              an airtight bond to the turntable platter
            </li>{" "}
            <li>Mirror finish on non machined areas, top and bottom</li>
          </ul>{" "}
          <div className="flex flex-right">
            <div className="contact-button">
              <a
                href="https://checkout.square.site/merchant/HF6HQG691QGFM/checkout/EEJGLF25RIJC2NEDFLMYTH7G"
                target="_blank"
              >
                Buy Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
