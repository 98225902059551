import classNames from "classnames";
import React, { useState } from "react";
import ReactModal from "react-modal";
import ForSaleModal from "./ForSaleModal";
import { Link } from "react-router-dom";
// import Moment from "react-moment";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(255,0,0,0.5)",
  },
};
export default function ItemForSale(props) {
  ReactModal.setAppElement("#root");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const theDate = new Date(props.itemDate).toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  const setModalOpenTrue = () => {
    setModalIsOpen(true);
  };
  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };
  const isHero = true;
  return (
    <div
      className={classNames(
        props.isHero
          ? "latestItemForSale hero-sidebar"
          : props.itemDisplay === "grid"
          ? props.itemPrice === "SOLD"
            ? "for-sale-item-grid for-sale-item-grid-sold"
            : "for-sale-item-grid"
          : props.itemPrice === "SOLD"
          ? "for-sale-item-sold"
          : "for-sale-item-list"
      )}
    >
      {props.isHero ? (
        <div className="container-heading text-500">Newest Arrival</div>
      ) : (
        ""
      )}
      <div className="item" onClick={setModalOpenTrue}>
        {props.itemDisplay === "grid" ? (
          <>
            <div className="item-image">
              <img src={props.itemImage.sizes.medium} />
            </div>
            <div className="item-details">
              <div className="item-heading section-subheading">
                <div
                  className="item-title"
                  dangerouslySetInnerHTML={{ __html: props.itemTitle }}
                ></div>
                <div className="item-date-price-grid">
                  {/* <div className="item-date">{theDate}</div> */}
                  <div className="item-price">{props.itemPrice}</div>
                </div>
              </div>
              <div
                className="item-text"
                dangerouslySetInnerHTML={{ __html: props.itemText }}
              ></div>
            </div>
          </>
        ) : (
          <>
            {props.isHero ? (
              <div className="item-image">
                <img src={props.itemImage.sizes.medium} />
              </div>
            ) : (
              ""
            )}
            <div className="item-details">
              <div className="item-heading section-subheading">
                <div className="item-title-date">
                  <div
                    className="item-title"
                    dangerouslySetInnerHTML={{
                      __html: props.itemTitle,
                    }}
                  ></div>
                  {/* <div className="item-date">{theDate}</div> */}
                </div>
                <div className="item-price">{props.itemPrice}</div>
              </div>
              {props.isHero ? (
                <div
                  className="item-text"
                  dangerouslySetInnerHTML={{
                    __html: props.isHero
                      ? props.itemText.substr(0, 200)
                      : props.itemText,
                  }}
                ></div>
              ) : (
                ""
              )}
            </div>
          </>
        )}
      </div>
      {props.isHero ? (
        ""
      ) : (
        <ReactModal
          isOpen={modalIsOpen}
          className={"modal"}
          closeTimeoutMS={750}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={setModalIsOpenToFalse}
          preventScroll={true}
          onAfterOpen={() => (document.body.style.overflow = "hidden")}
          onAfterClose={() => (document.body.style.overflow = "unset")}
        >
          <div
            onClick={setModalIsOpenToFalse}
            style={customStyles}
            className="modalClose"
          >
            close
          </div>
          <ForSaleModal itemProps={props}></ForSaleModal>
        </ReactModal>
      )}

      {props.isHero ? "" : ""}
      {props.isHero ? (
        <div className="flex flex-right">
          <Link to="/Shop" className="button">
            See all used/demo...
          </Link>
          <div className="contact-button">
            <a href={`mailto:info@corbyframing.ca?subject=${props.itemTitle}`}>
              Contact Us
            </a>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
